export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [];

export const dictionary = {
		"/(web)": [13,[4]],
		"/(admin)/dashboard": [5,[2]],
		"/(web)/homepage": [15,[4]],
		"/(web)/industries/[industry]": [16,[4]],
		"/(admin)/login": [6,[2]],
		"/(admin)/login/forgot-password": [7,[2]],
		"/(admin)/pay-invoice": [8,[2]],
		"/(web)/privacy": [17,[4]],
		"/(web)/reviews": [18,[4]],
		"/(admin)/setup": [9,[2,3]],
		"/(admin)/testing_homepage": [10,[2]],
		"/(admin)/testing_tutor": [11,[2]],
		"/(admin)/thank-you": [12,[2]],
		"/(web)/thanks": [19,[4]],
		"/(web)/top-services-classes": [20,[4]],
		"/(web)/tutors/[tutor]": [21,[4]],
		"/(web)/[service]": [14,[4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';